import Splide from '@splidejs/splide';

const mySplide = new Splide('.splide', {
    type: 'loop',
    autoplay: true,
}).mount();

document.addEventListener('turbolinks:before-visit', () => {
    mySplide.destroy(true);
});

let disp = function (obj1, obj2, obj3, obj4) {
    if (obj1) {
        obj1.classList.remove("none");
    }
    if (obj2) {
        obj2.classList.add("none");
    }
    if (obj3) {
        obj3.classList.add("none");
    }
    if (obj4) {
        obj4.classList.add("none");
    }
}
window.disp = disp;

/*历史文化廉政教育的动态显示*/
let ul21 = document.getElementById("21");
let ul22 = document.getElementById("22");
let ul23 = document.getElementById("23");

let ul31 = document.getElementById("31");
let ul32 = document.getElementById("32");
let ul33 = document.getElementById("33");

document.getElementById("wenhua").onmousemove = () => {
    disp(ul21, ul22, ul23);
}
document.getElementById("shijian").onmousemove = () => {
    disp(ul22, ul21, ul23);
}
document.getElementById("lilun").onmousemove = () => {
    disp(ul23, ul22, ul21);
}
document.getElementById("dianxingfengfan").onmousemove = () => {
    disp(ul31, ul32, ul33);
}
document.getElementById("yianshuoji").onmousemove = () => {
    disp(ul32, ul31, ul33);
}
document.getElementById("lianzhengketang").onmousemove = () => {
    disp(ul33, ul32, ul31);
}

/*底部一堆网站的动态显示*/
let a00 = document.getElementById("a00");
let a01 = document.getElementById("a01");
let a02 = document.getElementById("a02");
let a03 = document.getElementById("a03");

let a0 = document.getElementById("a0");
let a1 = document.getElementById("a1");
let a2 = document.getElementById("a2");
let a3 = document.getElementById("a3");

a00.onmousemove = function () {
    disp(a0, a1, a2, a3);
}
a01.onmousemove = function () {
    disp(a1, a0, a2, a3);
}
a02.onmousemove = function () {
    disp(a2, a0, a1, a3);
}
a03.onmousemove = function () {
    disp(a3, a0, a1, a2);
}

